import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import AssetAccounts from '@pw/components/AssetAccounts';
import { assetAccountsFields } from '@pw/components/AssetAccounts/assetAccountsFields';
import AssetForm from '@pw/components/AssetForm';
import ParentAssetID from '@pw/components/AssetID/ParentAssetID';
import assetIdFields from '@pw/components/AssetID/assetIdFields';
import parentAssetIdFields from '@pw/components/AssetID/parentAssetIdFields';
import { storageChargeCodeFields } from '@pw/components/ChargeCode/storageChargeCodeFields';
import {
	FormikCheckBox,
	FormikTextField,
} from '@pw/components/Forms/FormikForm';
import { TagSelect } from '@pw/components/Forms/TagSelect';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import LiquidProperties, {
	liquidPropertyFields,
} from '@pw/components/Liquid/LiquidProperties';
import Location from '@pw/components/Location';
import { locationFields } from '@pw/components/Location/locationFields';
import SKUField from '@pw/components/SKUSelector/SKUField';
import skuFields from '@pw/components/SKUSelector/skuFields';
import templateFields from '@pw/components/TemplateHandler/templateFields';
import { Body2, H5, H6 } from '@pw/components/Typography';
import { ASSET_TYPES } from '@pw/consts/asset';
import { SKU_TYPES } from '@pw/consts/sku';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useAccountPermissions } from '@pw/redux/containers/User/hooks';
import toUniqueLocation from '@pw/utilities/adapters/toUniqueLocation';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import { useCallback, useContext, useMemo, useState } from 'react';
import * as yup from 'yup';
import useAssetRevertHook from '@pw/utilities/hooks/logic/useAssetRevertHook';
import FormikContext from '@pw/context/FormikContext';
import RevertMessage from '@pw/components/Forms/RevertMessage';
import LiquidStatus from '@pw/components/Liquid/LiquidStatus';

function ReferenceField({ entity }) {
	const { values, setFieldValue } = useContext(FormikContext);
	const [historyExist, isSame, history] = useAssetRevertHook(
		entity,
		(e, e2) => e?.properties.reference === e2?.properties.reference,
	);
	const onRevert = useCallback(() => {
		setFieldValue('reference', history?.properties.reference ?? '');
	}, [history, setFieldValue]);

	const onUndo = useCallback(() => {
		setFieldValue('reference', entity?.properties.reference ?? '');
	}, [history, setFieldValue]);

	const isReverted = useMemo(() => values['reference'] === history?.properties?.reference, [history, values])

	return (
		<Box sx={{ position: 'relative' }}>
			{historyExist && !isSame && (
				<RevertMessage
					title='Reference'
					onRevert={onRevert}
					isReverted={isReverted}
					onUndo={onUndo}
					item={history?.properties?.reference}
					type="text"
				/>
			)}
			<FormikTextField name='reference' label='Reference' fullWidth />
		</Box>
	);
}

function propertyFields(fields = {}, converter) {
	const {
		cask: tags = [],
		imported = false,
		duty_paid = false,
		liquid = {},
		reference = '',
		sku_id = '',
		sku = {},
	} = fields;

	return {
		cask: [
			typeof tags === 'string' ? tags?.split(',') : tags,
			yup.array().of(yup.string()),
		],
		imported: [imported, yup.boolean()],
		duty_paid: [duty_paid, yup.boolean()],
		liquid: liquidPropertyFields(liquid, converter, false, true),
		reference: [reference, yup.string()],
		...skuFields(sku_id, sku),
	};
}

function Properties({ entity }) {
	const { editLiquid = false } = useAccountPermissions();

	const filter = (item) => item?.properties?.asset_type === ASSET_TYPES.cask;

	return (
		<FormikProvider path='properties'>
			<Stack spacing={2}>
				<H5>Cask Properties</H5>
				<SKUField
					title='Wood'
					skuTypes={[SKU_TYPES.TRACKED]}
					tags='cask'
					itemFilter={filter}
					entity={entity}
				/>
				<ReferenceField entity={entity} />
				<TagSelect name='cask' type='cask' entity={entity} />

				<LiquidStatus entity={entity} />
			</Stack>
			<LiquidProperties
				entity={entity}
				name='liquid'
				disabled={!editLiquid}
				useLiquidAccount
			/>
		</FormikProvider>
	);
}

function CaskPage(props) {
	const converter = useConverter();

	const [hideLocation, setHideLocation] = useState(false);
	const [entity, setEntity] = useState(null);

	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...templateFields(),
			...assetIdFields(initialValues),
			...skuFields(initialValues.sku_id, initialValues.sku),
			...parentAssetIdFields(
				initialValues.parent_asset_id,
				initialValues.parent_asset,
			),
			location: locationFields(initialValues.location),
			properties: propertyFields(initialValues.properties, converter),
			accounts: assetAccountsFields(initialValues.accounts),
			charge_code: storageChargeCodeFields(initialValues?.charge_code),
		}),
		[converter],
	);

	const handleInit = useCallback(
		(entity) => {
			setEntity(entity);
		},
		[setEntity],
	);

	const handleBeforeSave = (entity = {}) => {
		// const filteredEntity = processLiquid(entity);
		if (entity.parent_asset?.rw_asset_id) {
			return {
				...entity,
				unique_location_id: undefined,
			};
		}
		return {
			...entity,
			parent_asset_id: undefined,
			unique_location_id: toUniqueLocation(entity.location),
		};
	};

	return (
		<AssetForm
			assetLabel='Cask'
			assetType={ASSET_TYPES.cask}
			changeSetGenerator={changeSetGenerator}
			onBeforeSave={handleBeforeSave}
			onInit={handleInit}
			{...props}
		>
			<SKUField
				title='Marketplace Product'
				skuTypes={[SKU_TYPES.WIP]}
				entity={entity}
			/>

			<ParentAssetID entity={entity} hasParent={(v) => setHideLocation(v)} />

			<Location hide={hideLocation} name='location' entity={entity} />

			<Properties entity={entity} />

			<AssetAccounts entity={entity} />
		</AssetForm>
	);
}

export default withAppLayout(CaskPage, { title: 'Cask Management' });
