import getPermissions from '@pw/utilities/getPermissions';
import { setLanguageThunk } from '../language';
import loadCompanyState from './loadCompanyState';

export const handleSignin = async (i18n, response, fulfillWithValue, rejectWithValue, dispatch) => {
  // Save tokens required for http operations
  window.localStorage.setItem('auth-token', response.Authorization);
  window.localStorage.setItem('company-id', response.company_id);
  window.localStorage.setItem('account-id', response.account_id);

  // Load company settings
  if (response.company_id) {
    console.log('Companies', response.companies, response.company_id);
    const company = (response.companies ?? []).find(c => c.company_id === response.company_id);
    if (!company) {
      return rejectWithValue('Company not found!');
    }

    const perms = getPermissions(true, response.account_perms);

    const updatedCompany = await loadCompanyState(company, dispatch, perms);

    return fulfillWithValue({
      ...response,
      company: updatedCompany,
    });
  }

  return fulfillWithValue(response);
};
