import { Divider } from '@mui/material';
import { FormikDatePicker } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { Overline } from '@pw/components/Typography';
import FormikContext from '@pw/context/FormikContext';
import debounce from '@pw/utilities/debounce';
import { useReportLazyQuery } from '@pw/utilities/hooks/service/useReportQuery';
import { useContext, useEffect } from 'react';
import ReportAdjustment from './ReportAdjustment';
import ReportQuantity from './ReportQuantity';

export default function MonthlyReport({ refetchReport, isLoading }) {
	const { values, setFieldValue } = useContext(FormikContext);

	// const [refetchReport, { data: reportData, isLoading: isLoadingReport }] = useReportLazyQuery();

	useEffect(() => {
		if (values?.start_date && values?.end_date && values?.start_date < values?.end_date) {
			console.log('Start date', values?.start_date, 'End date', values?.end_date);
			debounce(() => {
				refetchReport({
					start_date: values?.start_date,
					end_date: values?.end_date,
				});
			}, 500);
		}
	}, [values?.start_date, values?.end_date]);

	return (
		<>
			<Divider><Overline>Period</Overline></Divider>
			<FlexBox spacing={2} alignItems='top'>
				<FormikDatePicker
					name='start_date'
					label='Period Start Date'
					fullWidth
					required
					disabled={isLoading}
				/>
				<FormikDatePicker
					name='end_date'
					label='Period End Date'
					fullWidth
					required
					disabled={isLoading}
				/>
			</FlexBox>

			<Divider><Overline>Stock</Overline></Divider>
			<ReportQuantity name='opening_stock' title='Opening Stock' />
			<ReportQuantity name='closing_stock' title='Closing Stock' />
			<ReportAdjustment name='adjustment' title='Adjustment' />

			<Divider><Overline>Receipts</Overline></Divider>
			<ReportQuantity
				name='receipts_from_importation'
				title='International Receipts'
			/>
			<ReportQuantity
				name='receipts_from_uk_and_other_warehouses'
				title='Domestic Receipts'
			/>
			<Divider><Overline>Removals</Overline></Divider>
			<ReportQuantity
				name='removals_to_home_use'
				title='Removals to Home Use'
			/>
			<ReportQuantity
				name='removals_to_exportation'
				title='Removals to Exportation'
			/>
			<ReportQuantity
				name='removals_to_other_uk_warehouses'
				title='Duty Suspended Removals'
			/>
			<ReportQuantity
				name='removals_to_other_duty_free_uses'
				title='Duty Free Removals'
			/>

			<Divider><Overline>Gains &amp; Losses</Overline></Divider>
			<ReportQuantity name='gains_in_storage' title='Storage Gains' />
			<ReportQuantity
				name='increases_from_operations'
				title='Gains from Operations'
			/>
			<ReportQuantity name='losses_in_storage' title='Storage Losses' />
			<ReportQuantity
				name='losses_from_operations'
				title='Losses from Operations'
			/>
		</>
	);
}
