import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import { FormikForm } from '@pw/components/Forms/FormikForm';
import Instructions from '@pw/components/Instructions';
import LiquidProperties, { liquidPropertyFields } from '@pw/components/Liquid/LiquidProperties';
import { ASSET_PROCESSED_STATUS } from '@pw/consts/requests';
import { AlertView } from '@pw/utilities/hooks/components/useAlertView';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo } from 'react';

export const calculateProductionDestinationLiquidUpdates = (values) => {
	console.log('Calculating', values);

	const { liquid = {} } = values;
	const { enable, level = {} } = liquid;

	if (!enable) {
		return liquid;
	}

	// Adjust the level settings..
	const {
		bl,
		la,
		abv,
		tcf,
		action_date,
	} = level;

	return {
		...liquid,
		level: {
			...level,
			filled_date: action_date,
			updated_bl: bl,
			updated_la: la,
			updated_abv: abv,
			updated_tcf: tcf,
		},
	};
};

function DestinationLiquidModal({
	item,
	open,
	onClose,
}) {
	const { enqueueSnackbar } = useSnackbar();

	const converter = useConverter();

	// We track the "amount" we want at the SKU Item level
	const { rw_asset_id, properties = {}, asset_type } = item ?? {};
	const { liquid = {} } = properties;

	const changeSet = useMemo(
		() => ({
			liquid: liquidPropertyFields(liquid, converter, true, true),
		}),
		[liquid, converter],
	);

	const handleSubmit = useCallback(
		(values) => {
			try {
				const liquid = calculateProductionDestinationLiquidUpdates(values);
				console.log('submitted', values, liquid);

				onClose({
					...item,
					properties: {
						...properties,
						liquid,
					},
					processed: ASSET_PROCESSED_STATUS.CONFIRMED,
				});
			} catch (e) {
				console.log('Failed to update', e);
				enqueueSnackbar(
					`Cannot update liquid settings for ${rw_asset_id}: ${e.message}!`,
					{
						variant: 'error',
					},
				);
			}
		},
		[item],
	);

	return (
		<ModalWithClose open={open} onClose={() => onClose()} title={rw_asset_id}>
			<Instructions>Adjust fill settings.</Instructions>

			<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
				<Stack spacing={2}>
					<LiquidProperties name='liquid' useWeight useBulk={false} />

					<AlertView />

					<Box className='action-buttons'>
						<TextButton
							size='small'
							handleClick={() => onClose()}
							color='secondary'
						>
							Cancel
						</TextButton>
						<FilledButton type='submit' size='small'>
							Save
						</FilledButton>
					</Box>
				</Stack>
			</FormikForm>
		</ModalWithClose>
	);
}

export default DestinationLiquidModal;
