import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { BasicMenu } from '@pw/components/Administration/components/BasicMenu';
import LiquidSection from '@pw/components/Dashboard/components/LiquidSection';
import ScanAnything from '@pw/components/Dashboard/components/ScanAnything';
import SensorPanel from '@pw/components/Dashboard/components/SensorPanel';
import Monitoring from '@pw/components/icons/Monitoring';
import TasksIcon from '@pw/components/icons/Tasks';
import { processRequestList } from '@pw/components/Tasks';
import { H5 } from '@pw/components/Typography';
import { useSensorDataLazyQuery } from '@pw/utilities/hooks/service/useSensorDataQuery';
import { useEffect, useMemo, useState } from 'react';
import AssetsSection from './components/AssetsSection';
import DashboardFilter from './components/DashboardFilter';
import RecentRequestsWidget from './components/RecentRequestsWidget';
import ReportWidget from './components/ReportWidget';
import TasksWidget from './components/TasksWidget';
import styles from './dashboard.styles';

export default function Dashboard({ data, refetch, facilities = [] }) {
	const [unique_location_id, setUniqueLocationId] = useState();
	const [fetchSensorData, { data: sensorData = {}, isLoading }] =
		useSensorDataLazyQuery({});

	// start loading with first facility
	useEffect(() => {
		facilities?.[0]?.id && setUniqueLocationId(facilities?.[0]?.id);
	}, [facilities]);

	// listen for changes in unique_location_id
	useEffect(() => {
		unique_location_id && fetchSensorData({ unique_location_id });
	}, [unique_location_id, fetchSensorData]);

	const isCompanyActive = true; // TODO: useSelector(selectIsCompanyActive);
	const {
		awaitingRequests = [],
		casks = [],
		skus = [],
	} = data ?? {};

	const tasks = useMemo(
		() => [
			...processRequestList(awaitingRequests),
		],
		[awaitingRequests],
	);

	return (
		<Stack sx={styles} className='root'>
			{!isCompanyActive && <ScanAnything />}
			{isCompanyActive && (
				<>
					<Box className='filter'>
						<DashboardFilter />
					</Box>
					{!!facilities?.length && (
						<Box className='content-row content-light'>
							<Box className='content-header'>
								<Box className='title-box'>
									<Monitoring height={24} width={24} />
									<H5>Monitoring</H5>
								</Box>
								<BasicMenu
									menuOptions={facilities.map(({ id, title }) => ({
										title,
										onClick: () => setUniqueLocationId(id),
									}))}
									isLoading={isLoading}
								/>
							</Box>
							<Box className='content-body content-3col'>
								<SensorPanel data={sensorData} />
							</Box>
						</Box>
					)}

					<AssetsSection skus={skus} casks={casks} />

					<LiquidSection />

					<Box className='content-row'>
						<Box className='content-header'>
							<Box className='title-box'>
								<TasksIcon height={24} width={24} />
								<H5>Activity</H5>
							</Box>
						</Box>

						<Box className='content-body content-3col'>
							<TasksWidget refetch={refetch} data={tasks ?? []} />
							<RecentRequestsWidget
								data={data?.requests ?? []}
								title='Deliveries/Shipments'
							/>
							<ReportWidget title='Report' />
						</Box>
					</Box>
				</>
			)}
		</Stack>
	);
}
