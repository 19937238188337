// Generated at 12/7/2024, 1:33:58 AM

export const bestUnits = [
  [[["deg",1]],[["mm2",1],["cm2",100],["m2",1000000],["km2",1000000000000]],[["bits",1],["B",8],["KB",8000],["MB",8000000],["GB",8000000000],["TB",8000000000000],["PB",8000000000000000]],[["J",1],["Wh",3600],["kWh",3600000],["MWh",3600000000],["GWh",3600000000000]],[["N",1]],[["mm",1],["cm",10],["m",1000],["km",1000000]],[["mg",1],["g",1000],["kg",1000000]],[["W",1],["kW",1000],["MW",1000000],["GW",1000000000],["TW",1000000000000],["PW",1000000000000000]],[["Pa",1]],[["C",1]],[["fs",1],["ps",1000],["ns",1000000],["µs",1000000000],["ms",1000000000000],["s",1000000000000000],["min",60000000000000000],["h",3600000000000000000],["d",86400000000000000000],["y",3.1536e+22]],[["mL",1],["L",1000]]],
  [[["deg",1]],[["sq in",1],["sq ft",144],["ac",6272640],["sq mi",4014489600]],[["bits",1],["B",8],["KiB",8192],["MiB",8388608],["GiB",8589934592],["TiB",8796093022208],["PiB",9007199254740992]],[["J",1],["Wh",3600],["kWh",3600000],["MWh",3600000000],["GWh",3600000000000]],[["lbf",1]],[["in",1],["ft",12],["yd",36],["mi",63360]],[["oz",1],["lb",16]],[["W",1],["kW",1000],["MW",1000000],["GW",1000000000],["TW",1000000000000],["PW",1000000000000000]],[["psi",1]],[["F",1]],[["fs",1],["ps",1000],["ns",1000000],["µs",1000000000],["ms",1000000000000],["s",1000000000000000],["min",60000000000000000],["h",3600000000000000000],["d",86400000000000000000],["y",3.1536e+22]],[["tsp",1],["tbsp",3],["fl oz",6],["cup",48],["pt",96],["qt",192],["gal",768]]]
] as const;

export type BestUnits = {
  imperial: 'deg' | 'sq in' | 'sq ft' | 'ac' | 'sq mi' | 'bits' | 'B' | 'KiB' | 'MiB' | 'GiB' | 'TiB' | 'PiB' | 'J' | 'Wh' | 'kWh' | 'MWh' | 'GWh' | 'lbf' | 'in' | 'ft' | 'yd' | 'mi' | 'oz' | 'lb' | 'W' | 'kW' | 'MW' | 'GW' | 'TW' | 'PW' | 'psi' | 'F' | 'fs' | 'ps' | 'ns' | 'µs' | 'ms' | 's' | 'min' | 'h' | 'd' | 'y' | 'tsp' | 'tbsp' | 'fl oz' | 'cup' | 'pt' | 'qt' | 'gal';
  metric: 'deg' | 'mm2' | 'cm2' | 'm2' | 'km2' | 'bits' | 'B' | 'KB' | 'MB' | 'GB' | 'TB' | 'PB' | 'J' | 'Wh' | 'kWh' | 'MWh' | 'GWh' | 'N' | 'mm' | 'cm' | 'm' | 'km' | 'mg' | 'g' | 'kg' | 'W' | 'kW' | 'MW' | 'GW' | 'TW' | 'PW' | 'Pa' | 'C' | 'fs' | 'ps' | 'ns' | 'µs' | 'ms' | 's' | 'min' | 'h' | 'd' | 'y' | 'mL' | 'L';
};