import { clientFields } from '@pw/components/Client/clientFields';
import { FormikSelect, FormikTextField } from '@pw/components/Forms/FormikForm';
import useInventorySelectorHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import TTBReport_75 from '@pw/components/Report/AlcoholFuelPlantReport/TTBReport_75';
import TTBReport_75Fields from '@pw/components/Report/AlcoholFuelPlantReport/TTBReport_75Fields';
import TTBReport_24 from '@pw/components/Report/ExciseTaxReturn/TTBReport_24';
import TTBReport_24Fields from '@pw/components/Report/ExciseTaxReturn/TTBReport_24Fields';
import MonthlyReport from '@pw/components/Report/MonthlyReport';
import monthlyReportFields from '@pw/components/Report/monthlyReportFields';
import TTBReport_43 from '@pw/components/Report/MonthlyReportofProcessingDenaturingOperations/TTBReport_43';
import TTBReport_43Fields from '@pw/components/Report/MonthlyReportofProcessingDenaturingOperations/TTBReport_43Fields';
import TTBReport_28 from '@pw/components/Report/MonthlyReportofProcessingOperations/TTBReport_28';
import TTBReport_28Fields from '@pw/components/Report/MonthlyReportofProcessingOperations/TTBReport_28Fields';
import TTBReport_11 from '@pw/components/Report/MonthlyReportofStorageOperations/TTBReport_11';
import TTBReport_11Fields from '@pw/components/Report/MonthlyReportofStorageOperations/TTBReport_11Fields';
import TTBReport from '@pw/components/Report/MonthlyReportofProductionOperations/TTBReport';
import TTBReportFields from '@pw/components/Report/MonthlyReportofProductionOperations/TTBReportFields';
import RequestForm from '@pw/components/RequestForm';
import requestIdFields from '@pw/components/RequestID/requestIdFields';
import DestinationSKUModal from '@pw/components/SKUSelector/modals/DestinationSKUModal';
import DutySubmissionSourceAssetModal from '@pw/components/SKUSelector/modals/DutySubmissionSourceAssetModal';
import SourceSKUModal from '@pw/components/SKUSelector/modals/SourceSKUModal';
import { H4 } from '@pw/components/Typography';
import { ASSET_TYPES } from '@pw/consts/asset';
import {
	REPORT_OPTIONS,
	REPORT_OPTIONS_US,
	REPORT_TYPES,
} from '@pw/consts/reports';
import {
	ASSET_PROCESSED_STATUS,
	REQUEST_STATUS,
	REQUEST_TYPES,
} from '@pw/consts/requests';
import { SKU_TYPES } from '@pw/consts/sku';
import { FormikProvider } from '@pw/providers/FormikProvider';
import {
	useCompanyName,
	useCurrentCompany,
	useIsUsUser,
} from '@pw/redux/containers/User';
import toTaggedAsset from '@pw/utilities/adapters/toTaggedAsset';
import debounce from '@pw/utilities/debounce';
import useGetId from '@pw/utilities/hooks/logic/useGetId';
import useReportSummaryQuery, { useReportSummaryLazyQuery } from '@pw/utilities/hooks/service/useReportSummaryQuery';
import { useCallback, useMemo, useState } from 'react';
import * as yup from 'yup';

export function convertSourceAsset(item) {
	const { properties = {} } = item ?? {};
	const { liquid = {} } = properties;
	const { level = {} } = liquid;
	const { bl = 0, enable: le = false } = level;

	// Only add non-empty assets
	if (bl && le) {
		return toTaggedAsset({
			...item,
			processed: ASSET_PROCESSED_STATUS.PENDING,
			properties: {
				...properties,
				liquid: {
					...liquid,
					level: {
						...level,
						expected_bl: bl,
					},
				},
			},
		});
	}
	return null;
}

// eslint-disable-next-line react/prop-types
function Properties({ children, reportType, refetchReport, isLoading }) {
	// const { readonly } = useContext(FormikContext);
	return (
		<FormikProvider path='properties'>
			{reportType === REPORT_TYPES['5110.4'] ? (
				<TTBReport />
			) : reportType === REPORT_TYPES['5110.75'] ? (
				<TTBReport_75 />
			) : reportType === REPORT_TYPES['5000.24'] ? (
				<TTBReport_24 />
			) : reportType === REPORT_TYPES['5110.43'] ? (
				<TTBReport_43 />
			) : reportType === REPORT_TYPES['5110.28'] ? (
				<TTBReport_28 />
			) : reportType === REPORT_TYPES['5110.11'] ? (
				<TTBReport_11 />
			) : reportType === REPORT_TYPES.MONTHLY ? (
				<MonthlyReport refetchReport={refetchReport} isLoading={isLoading} />
			) : (
				<>
					{children}
					<FormikTextField
						name='payment_reference'
						label='Payment Reference Number'
					/>
				</>
			)}
		</FormikProvider>
	);
}

function propertyFields(fields = {}, company, companyName) {
	const { payment_reference = '', reportType = REPORT_TYPES.MONTHLY } = fields;
	return {
		...(reportType === REPORT_TYPES['5110.4']
			? TTBReportFields(fields, company, companyName)
			: reportType === REPORT_TYPES['5110.75']
				? TTBReport_75Fields(fields, company, companyName)
				: reportType === REPORT_TYPES['5000.24']
					? TTBReport_24Fields(fields, company, companyName)
					: reportType === REPORT_TYPES['5110.43']
						? TTBReport_43Fields(fields, company, companyName)
						: reportType === REPORT_TYPES['5110.28']
							? TTBReport_28Fields(fields, company, companyName)
							: reportType === REPORT_TYPES['5110.11']
								? TTBReport_11Fields(fields, company, companyName)
								: reportType === REPORT_TYPES.MONTHLY
									? monthlyReportFields(fields)
									: {
											payment_reference: [
												payment_reference,
												yup
													.string()
													.required('Please enter payment reference number'),
											],
										}),
	};
}

function DutySubmissionForm() {
	const { id } = useGetId();
	const isUSUser = useIsUsUser();
	const company = useCurrentCompany();
	const companyName = useCompanyName();

	const [reportType, setReportType] = useState(REPORT_TYPES.MONTHLY);

	const source_filter = {
		asset_types: [ASSET_TYPES.cask, ASSET_TYPES.ibc, ASSET_TYPES.filling_tank],
		sku_types: [SKU_TYPES.FINISHED],
	};

	const [refetchReport, { data: reportSummary, isLoading }] = useReportSummaryLazyQuery();

	const [
		[sourceSkus, initSourceSkus],
		[sourceAssets, initSourceAssets],
		,
		SourceInventory,
		SourceModals,
	] = useInventorySelectorHook({
		title: 'Inventory',
		filter: source_filter,
		assetFilter: (a) =>
			[
				ASSET_TYPES.ibc,
				ASSET_TYPES.cask,
				ASSET_TYPES.tanker,
				ASSET_TYPES.filling_tank,
			].includes(a.asset_type),
		assetAdapter: convertSourceAsset,
		SKUModal: SourceSKUModal,
		skuModalProps: { include_tax_code: true, duty_paid: true },
		AssetModal: DutySubmissionSourceAssetModal,
		dutyPaidSummary: true,
	});

	const [
		[destinationSkus],
		[],
		initDestinations,
		DestinationInventory,
		DestinationModals,
	] = useInventorySelectorHook({
		title: 'Services',
		filter: {
			sku_types: [SKU_TYPES.SERVICE],
		},
		SKUModal: DestinationSKUModal,
	});

	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...requestIdFields(initialValues),
			client: clientFields(initialValues?.client),
			properties: propertyFields(
				{
					...reportSummary,
					...initialValues?.properties,
					reportType,
				},
				company,
				companyName,
			),
		}),
		[reportSummary, reportType, company, companyName],
	);

	/**
	 * Initialization function
	 * @type {(function(*): void)|*}
	 */
	const handleInit = useCallback((entity) => {
		const {
			properties,
			request_status = REQUEST_STATUS.PENDING,
			sku_sources = [],
			sources = [],
			sku_destinations = []
		} = entity ?? {};

		// set initial state
		initSourceSkus(sku_sources);
		initSourceAssets(sources);
		initDestinations(sku_destinations);
		setReportType(properties?.report_type ?? REPORT_TYPES.MONTHLY);
	}, [initSourceSkus, initSourceAssets, initDestinations]);

	/**
	 * Prior to saving the entity, this is called to inject in the sources
	 * @type {function(*): *&{sources: *, sku_sources: *}}
	 */
	const handleBeforeSave = useCallback(
		(entity) => {
			return {
				...entity,
				sku_sources: sourceSkus,
				sources: sourceAssets,
				sku_destinations: destinationSkus,
				properties: {
					...(entity?.properties ?? {}),
					report_type: reportType,
				},
			};
		},
		[sourceSkus, sourceAssets, destinationSkus, reportType],
	);

	console.log('reportType', reportType, reportSummary);

	return (
		<RequestForm
			requestLabel='Duty Submission'
			requestType={REQUEST_TYPES.duty_submission}
			changeSetGenerator={changeSetGenerator}
			onInit={handleInit}
			onBeforeSave={handleBeforeSave}
		>
			<H4>Report Details</H4>
			<FormikSelect
				label={'Report Type'}
				name='report_type'
				options={isUSUser ? REPORT_OPTIONS_US : REPORT_OPTIONS}
				fullWidth
				onChange={(e) => setReportType(e.target.value)}
				value={reportType}
			/>
			<Properties reportType={reportType} refetchReport={refetchReport} isLoading={isLoading}>
				<SourceInventory />
				<DestinationInventory />
				<SourceModals />
				<DestinationModals />
			</Properties>
		</RequestForm>
	);
}

export default withAppLayout(DutySubmissionForm, { title: 'Duty Submission' });
